<template>
    <div class="route-today">
        <div class="title">
            <p>מסלול היום</p>
        </div>
        <div class="loading" v-if="loading">
            <img src="@/assets/loader.gif" alt="">
            <p>טוען, אנא המתן ...</p>
        </div>
        <div class="route">
            <table id="table" v-if="route_today">
                <tr>
                    <th>מס</th>
                    <th>לקוח</th>
                    <th>עיר</th>
                    <th>כתובת</th>
                    <th>הזמנה</th>
                    <th>הערה</th>
                    <th>אלבום</th>
                </tr>
                <template v-for="(client,i) in route_today" :key="i">
                    <tr :style="check_order(client)">
                        <td>{{client.CUSTNAME}}</td>
                        <td>{{client.CUSTDES}}</td>
                        <td v-if="client.profile">{{client.profile.city}}</td>
                        <td v-if="client.profile">{{client.profile.adress}}</td>
                        <td style="max-width:100px; text-align:center; padding:5px;">{{client.ORDNAME}}</td>
                        <td style="text-align:center;">{{client.SUBJECT}}</td>
                        <td>
                            <div class="open-album" @click="lunch_catalog(client)">
                                <p>הזמנה</p>
                            </div>
                        </td>
                    </tr>
                </template>
            </table>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed, onMounted } from '@vue/runtime-core';
import store from '../../store';
import { projectFirestore, projectFunctions } from '../../firebase/config';
import Swal from 'sweetalert2';
import router from '../../router';
export default {
props:[],
setup(){
    const loading = ref(false);
    const user = ref(computed(() => {
        return store.getters.user
    }))
    const route_today = ref([]);

    onMounted(() => {
        loading.value = true;
        setTimeout(async () => {
            if(user.value){
                route_today.value = [];
                console.log('Fetching route!');
                const route = await projectFunctions.httpsCallable("agents_route_today")({agent_id: user.value.agent})
                if(route.data){
                    for(let  i =0; i <route.data.length; i++){
                        let client = route.data[i]
                        let inx = route_today.value.findIndex(_clinet => {
                            return _clinet.CUSTNAME == client.CUSTNAME
                        })
                        if(inx == -1){
                            route_today.value.push(client)
                        }else{
                            route_today.value[inx].ORDNAME += ' '+ client.ORDNAME
                        }
                    }

                    for(let i = 0; i < route_today.value.length; i++){
                        const doc = await projectFirestore.collection('Clients').doc( route_today.value[i].CUSTNAME).get();
                        if(doc.exists){
                            route_today.value[i].profile = doc.data()
                        }
                        else{
                            route_today.value.splice(i , 1);
                        }
                    }

                    route_today.value.sort((aa, bb) => {
                        if(aa.profile && bb.profile){
                            if(aa.profile.city > bb.profile.city){return -1}
                            if(aa.profile.city < bb.profile.city){return 1}
                        }
                    })

                    loading.value = false;
                }
            }else{
                window.location.reload();
            }
        }, 1500);
    })

    const lunch_catalog = async (client) => {
        store.dispatch("clear_cart");
        store.state.loader = true;
        let assortment = '10001';
        if(!client.profile){
            Swal.fire({
                icon: 'info',
                title: 'לקוח לא מעודכן',
                text: 'הלקוח שנבחר איננו מעודכן במערכת, נא לבקש לעשות טעינת לקוחות. נמשיך לקטלוג עם אלבום סוכנים כללי'
            })
        }
        if(client.profile.merakez){
            assortment = client.profile.merakez
            store.state.client_cart.assortment = assortment;
        }
        if(client.profile.price_list_id){
            console.log('טוען מחירון' + client.profile.price_list_id);
            store.state.user.price_list = client.profile.price_list_id
        }
        store.state.client_cart.client = client.profile.makat;
        store.state.user.assortment = assortment
        store.state.user.client = client.profile.makat
        store.state.user.client_data = await projectFirestore.collection('Clients').doc(client.profile.makat).get().then(doc => {
            return doc.data()
        })
        
        router.push('/catalog')
    }

    const check_order = (client) => {
        if(client.ORDNAME.toString().length > 2){
            return 'background: var(--success);'
        }
    }
    return{
        loading, route_today, check_order, lunch_catalog
    }
}
}
</script>

<style scoped>
.route-today{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.title{
    width: 100%;
    max-width: 770px;
    height: 60px;
    background: var(--secondary);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-shadow: 0 0 3px black;
    font-size: 24px;
    flex-shrink: 0;
}
.loading{
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100% - 60px);
    background: var(--main);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    flex-direction: column;
    font-size: 21px;
}
.loading img{
    width:150px;
    height: auto;
    border-radius: 15px;
    margin-bottom: 25px;
}
.route{
    width: 100%;
    max-width: 770px;
    height: fit-content;
    padding: 5px;
    overflow-x: auto;
}
#table {
    border-collapse: collapse;
    width: 100%;
    background: white;
}
#table td, #table th {
  border: 1px solid #ddd;
  padding: 8px;
}
#table tr:nth-child(even){background-color: #f2f2f2;}
#table tr:nth-child(odd){background-color: #e7e6e6;}

#table tr:hover {background-color: #ddd;}

#table td:last-child{
    text-align: center;
}
#table th{
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: var(--purple);
  color: white;
  position: sticky;
  top: 0;
}
.open-album{
    width: 100%;
    height: 100%;
    min-height: 50px;
    background: var(--success);
    padding: 5px;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>